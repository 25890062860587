.ui-fileupload .ui-corner-top {
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.ui-fileupload .ui-corner-bottom {
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
}
.ui-fileupload .ui-button {
  border-radius: 2px;
}