@import "~@ng-select/ng-select/themes/default.theme.css";

.modal-lg,
.modal-xl {
    max-width: 1250px !important;
}

.modal-md {
    max-width: 850px !important;
}

.selector {
    position: relative;
    width: 100%;
    background-color: var(--smoke-white);
    height: 80px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 9999px;

}

.selecotr-item {
    position: relative;
    flex-basis: calc(80% / 2);
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.selector-item_radio {
    appearance: none;
    display: none;
}

.selector-item_label {
    position: relative;
    height: 80%;
    width: 100%;
    text-align: center;
    border-radius: 9999px;
    line-height: 400%;
    font-weight: 900;
    transition-duration: .5s;
    transition-property: transform, color, box-shadow;
    transform: none;
}

.selector-item_radio:checked+.selector-item_label {
    background-color: var(--blue);
    color: var(--white);
    box-shadow: 0 0 4px rgba(0, 0, 0, .5), 0 2px 4px rgba(0, 0, 0, .5);
    transform: translateY(-2px);
}

@media (max-width:480px) {
    .selector {
        width: 90%;
    }
}

/* Border Color */
.ng-select.ng-invalid.ng-touched .ng-select-container {
    border-color: red;
}

/* Arrow Color */
.ng-select.ng-invalid.ng-touched .ng-arrow-wrapper .ng-arrow {
    border-color: red transparent transparent
}

/* Placeholder Color */
.ng-select.ng-invalid.ng-touched .ng-select-container .ng-placeholder {
    color: red;
}

.sub-title {
    color: #6c757d;
    font-weight: 500;
}


/*asdasd */
 

/* You can add global styles to this file, and also import other style files */

.margin-left-5 {
    margin-left: 5px !important;
}

.margin-right-5 {
    margin-right: 5px !important;
}

.margin-top-5 {
    margin-top: 5px !important;
}

.margin-top-15 {
    margin-top: 15px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-5 {
    margin-bottom: 5px !important;
}

.margin-bottom-15 {
    margin-bottom: 15px !important;
}

.padding-left-5 {
    padding-left: 5px !important;
}

.padding-right-5 {
    padding-right: 5px !important;
}

.padding-top-5 {
    padding-top: 5px !important;
}

.padding-bottom-5 {
    padding-bottom: 5px !important;
}

.width-percent-5 {
    width: 5%;
}

.width-percent-10 {
    width: 10%;
}

.width-percent-15 {
    width: 15%;
}

.width-percent-20 {
    width: 20%;
}

.width-percent-30 {
    width: 30%;
}

.width-percent-40 {
    width: 40%;
}

.width-percent-50 {
    width: 50%;
}

.width-percent-100 {
    width: 100%;
}


/* Sweet alert */

.swal2-title {
    display: inline-block !important;
}

body.swal2-height-auto {
    height: inherit !important;
}

.swal2-popup.swal2-toast {
    padding: 10px 12px !important;
    display: block !important;
}

.swal2-popup.swal2-toast .swal2-header,
.swal2-popup.swal2-toast .swal2-title,
.swal2-popup.swal2-toast .swal2-content {
    text-align: left !important;
    margin: 0 !important;
}

.swal2-popup.swal2-toast .swal2-title {
    margin-bottom: 4px !important;
}

.swal2-icon.swal2-info::before,
.swal2-icon.swal2-warning::before,
.swal2-icon.swal2-success::before,
.swal2-icon.swal2-error::before {
    content: "";
}

@keyframes glowing {
    0% {
        opacity: 0.3;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.3;
    }
}

.glowing {
    animation: glowing 2s infinite;
}

.root-initial-loader .overlay {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(51, 51, 51, 0.8);
    z-index: 99999 !important;
    width: 100%;
    height: 100%;
}

.root-initial-loader i {
    position: absolute;
    color: rgb(91, 167, 234);
    z-index: 100000 !important;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.root-initial-loader .fa-spin-2x {
    -webkit-animation: fa-spin 1s infinite linear;
    animation: fa-spin 1s infinite linear;
}

ngx-spinner .loading-text {
    font-weight: bold;
    color: white;
    font-size: 1.3em;
}

.red-btn {
    background-color: #10487c;
    border: 0;
    border-radius: 0;
    color: #FFF;
    cursor: pointer;
    outline: none;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    border-radius: 0.42rem;
    text-transform: uppercase;
}

.grey-btn {
    background-color: #ebedf3;
    border: 0;
    border-radius: 0;
    color: #3F4254;
    cursor: pointer;
    outline: none;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
    border-radius: 0.42rem;
    text-transform: uppercase;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    background: #f3f3f3!important;
}

.wizard.wizard-3 .wizard-nav .wizard-steps {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    margin-right: 1rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step:last-child {
    margin-right: 0;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    color: #B5B5C3;
    padding: 2rem 0.5rem 0rem 0.5rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-title span {
    font-size: 2rem;
    margin-right: 0.5rem;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar {
    height: 4px;
    width: 100%;
    background-color: #EBEDF3;
    position: relative;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label .wizard-bar:after {
    content: " ";
    position: absolute;
    top: 0;
    left: 0;
    height: 4px;
    width: 0;
    background-color: transparent;
    -webkit-transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, -webkit-box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease;
    transition: color 0.15s ease, background-color 0.15s ease, border-color 0.15s ease, box-shadow 0.15s ease, -webkit-box-shadow 0.15s ease;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label {
    color: #10487c;
}

.wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step[data-wizard-state=current] .wizard-label .wizard-bar:after {
    width: 100%;
    background-color: #10487c;
}

.News-Update .mat-tab-body-content {
    padding: 30px 0;
}

@media (max-width: 991.98px) {
    .wizard.wizard-3 .wizard-nav .wizard-steps {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -ms-flex-align: start;
        align-items: flex-start;
    }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step {
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        position: relative;
        width: 100%;
    }
    .wizard.wizard-3 .wizard-nav .wizard-steps .wizard-step .wizard-label {
        -webkit-box-pack: start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-flex: 0;
        -ms-flex: 0 0 100%;
        flex: 0 0 100%;
        padding: 1rem 0;
    }
}

bs-dropdown-container {
    z-index: 1060 !important;
}

.ng-dropdown-panel {
    z-index: 1060!important;
}

.order-list .card-img-overlay {
    background: url(/assets/common/images/play-button.png) center center no-repeat;
    cursor: pointer;
    background-size: 50px;
}

.order-list {
    margin-left: unset !important;
    max-width: 100%;
    border: solid 1px #ccc;
    min-height: 60px;
    display: flex !important;
    /* flex-wrap: nowrap !important; */
    overflow-x: auto !important;
    flex-direction: row;
    background: white;
    border-radius: 4px;
    overflow: hidden;
    padding: 1rem;
}

.order-box {
    /* border-right: solid 1px #ccc;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    font-size: 14px;
    flex-grow: 1;
    flex-basis: 0; */
    padding-right: 8rem;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-sizing: border-box;
    cursor: move;
    background: white;
    flex-basis: 0;
}

.order-box .pi-trash {
    font-size: 1.3rem;
    z-index: 10;
    position: absolute;
    top: -7px;
    right: 0;
    background-color: #cb0313;
    padding: 0.4rem;
    color: white;
    cursor: pointer;
    border-radius: 50%;
}

.cdk-drag-preview {
    box-sizing: border-box;
    border-radius: 4px;
    box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
    opacity: 0;
}

.cdk-drag-animating {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.order-box:last-child {
    border: none;
}

.order-list.cdk-drop-list-dragging .order-box:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.w3-wrapper {
    color: #ffffff;
    text-decoration: none;
    font-size: 15px;
    position: relative;
    padding: 11px 7px 15px 7px;
    display: block;
    border-bottom: 3px solid transparent;
    transition: all 0.3s;
    box-sizing: border-box;
    height: 54px;
    width: 38px;
}

.notify-icon {
    width: 25px;
    height: 25px;
    display: block;
    position: relative;
    background-position: center center;
    animation: ring 1.6s linear infinite;
}

.w3-count {
    position: absolute;
    padding: 2px 3px;
    background: #ff4f38;
    color: #fff;
    border-radius: 30px;
    top: 7px;
    right: 0px;
    min-width: 12px;
    font-size: 11px;
    z-index: 200;
    text-align: center;
    animation: w3-alert-red 1.6s infinite;
}


/*Admin Panel CSS*/

.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-icon,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item.menu-item-open>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item .menu-submenu .menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-here>.menu-link .menu-arrow,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-text,
.aside-menu .menu-nav>.menu-item.menu-item-open>.menu-link .menu-arrow,
.aside-menu .menu-nav>.menu-item:not(.menu-item-parent):not(.menu-item-open):not(.menu-item-here):not(.menu-item-active):hover>.menu-link .menu-arrow {
    color: #181C32 !important;
    font-weight: 500;
}

.btn.btn-primary,
.btn.btn-bg-danger {
    color: #FFF!important;
    background-color: #10487c!important;
    border-color: #10487c!important;
    opacity: 1!important;
}

.symbol.symbol-light-success .symbol-label {
    background-color: #ebedf3!important;
    color: #181C32!important;
}

.btn.btn-light-primary {
    color: #181C32!important;
    background-color: #ebedf3!important;
    border-color: transparent;
}

.btn.btn-light-primary i {
    color: #181C32!important;
}

/* .label.label-warning {
    background-color: #10487c!important;
} */

.label.label-unwarning,
.label.label-danger {
    border-color: rgb(211 16 30 / 50%)!important;
    background-color: rgb(211 16 30 / 50%)!important;
    color: #fff;
}

.p-fileupload .p-fileupload-buttonbar {
    background: rgb(235 237 243 / 81%);
    border: 1px solid #d7d7d8;
    border-bottom: 0;
}

.p-fileupload .p-fileupload-content {
    border: 1px solid #d7d7d8;
}

.p-button,
.p-fileupload-choose:not(.p-disabled):active,
.p-fileupload-choose:not(.p-disabled):hover,
.p-button:enabled:hover {
    background: #10487c;
    border: 1px solid #10487c;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
    background-color: #ebedf3;
}

.ng-select.ng-select-focused,
.p-button:focus {
    box-shadow: unset!important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
    border-color: #ccc!important;
    box-shadow: unset!important;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
    border-left: 1px solid #a4a4a4
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected,
.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
    background-color: #ebedf3;
}

.theme-default .bs-datepicker-head {
    background-color: #ebedf3;
}

.bs-datepicker-head button,
.theme-default .bs-datepicker-body table td.week span {
    color: #181C32;
}

.badge-success {
    color: #181C32!important;
    background-color: #ebedf3!important;
}

.nav .nav-link.active {
    color: #181C32!important;
}

.nav .nav-link:hover:not(.disabled) {
    color: rgb(24 28 50 / 75%)!important;
}

.asas-black {
    color: #181C32;
}

#kt_wrapper {
    background-color: #ebedf3;
}

/* .label.label-success {
    color: #181C32!important;
    background-color: rgba(224, 224, 224, 1)!important;
} */

.label.label-green {
    color: #ffffff!important;
    background-color: rgb(83 209 81)!important;
}

.btn.btn-default:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #181C32!important;
}

.label.label-primary {
    color: #181C32!important;
    background-color: #ebedf3!important;
}

.form-control:focus {
    border-color: #ebedf3!important;
}

.btn.btn-success,
.btn.btn-success:hover:not(.btn-text):not(:disabled):not(.disabled),
.btn.btn-success:focus:not(.btn-text),
.btn.btn-success.focus:not(.btn-text),
.btn.btn-info,
.btn.btn-danger {
    color: #181C32!important;
    background-color: #ebedf3!important;
    border-color: #ebedf3!important;
}

.btn.btn-success i,
.btn.btn-info i,
.btn.btn-danger i {
    color: #181C32!important;
}

.btn.btn-bg-danger .fa-bullhorn {
    color: #fff;
}

.swal2-icon.swal2-warning {
    border-color: #10487c!important;
    color: #10487c!important;
}

.swal2-styled.swal2-confirm,
.scrolltop,
.mat-tab-group.mat-primary .mat-ink-bar {
    background-color: #10487c;
}

.swal2-styled.swal2-cancel {
    background-color: #ebedf3;
    color: #000;
}

.swal2-actions:not(.swal2-loading) .swal2-styled:hover,
.swal2-actions:not(.swal2-loading) .swal2-styled:active {
    background-image: unset;
}

.mat-tab-body-wrapper {
    margin-top: 1.5rem;
}

.ck-toolbar,
.ck.ck-button.ck-on:not(.ck-disabled):hover,
a.ck.ck-button.ck-on:not(.ck-disabled):hover,
.ck.ck-button:not(.ck-disabled):hover,
a.ck.ck-button:not(.ck-disabled):hover,
.ck.ck-button.ck-on,
a.ck.ck-button.ck-on {
    background-color: #ebedf3!important;
}

.ck.ck-list__item .ck-button.ck-on {
    background: #ebedf3;
    color: #181C32!important;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
    border: 1px solid #c7c7c7!important;
}

.ck.ck-button:focus,
.ck.ck-input-text:focus {
    border: #c7c7c7!important;
}

.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: rgb(235, 237, 243)!important;
    border-color: rgb(235, 237, 243)!important;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    border-color: #10487c;
}

.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider,
.p-inputswitch.p-inputswitch-checked:not(.p-disabled):hover .p-inputswitch-slider {
    background: #10487c;
}

.form-control-feedback {
    color: #10487c;
}

.text-warning {
    color: #b3b7c2!important;
}

.checkbox>input:checked~span {
    background-color: #10487c!important;
}

.btn.btn-outline-success {
    color: #181c32!important;
    border-color: #181c32!important;
}

.btn.btn-outline-success i,
.btn.btn-outline-success.dropdown-toggle:after {
    color: #181c32!important;
}

.btn.btn-outline-primary,
.btn.btn-outline-primary:hover:not(.btn-text):not(:disabled):not(.disabled) {
    color: #181c32!important;
    border-color: #181c32!important;
    background-color: #fff!important;
}

.btn.btn-outline-primary i {
    color: #181c32!important;
}

.text-success {
    color: rgb(208 1 16) !important;
}

.nav.nav-pills .nav-link.active {
    background-color: rgb(235 237 243)!important;
}

.alert.alert-success {
    background-color: #ced2dc!important;
    border-color: #ced2dc!important;
}

.btn.btn-clean:hover:not(.btn-text):not(:disabled):not(.disabled) i,
.show .btn.btn-clean.btn-dropdown i {
    color: rgb(181, 181, 195)!important;
}

#setAllNotificationsAsReadLink,
#openNotificationSettingsModalLink {
    color: #181c32!important;
}

.btn.btn-link-success,
.navi .navi-item .navi-link:hover,
.navi .navi-item .navi-link:hover .navi-text {
    color: #181c32!important;
}

.text-info,
.navi .navi-item .navi-link .navi-icon i {
    color: #c8c8d2 !important;
}


/* .text-dark-50 {
    color: #181C32 !important;
} */

@media (min-width: 992px) {
    .brand .btn:hover .svg-icon svg g [fill] {
        fill: #b4b8ce!important;
    }
}

.overlay {
    background-color: rgba(255, 255, 255, 0.8)!important;
}


/*Admin Panel CSS End*/

@keyframes w3-alert-red {
    0% {
        -webkit-box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7);
        -moz-box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7);
        box-shadow: 0 0 0 0 rgba(254, 57, 5, 0.7);
    }
    100% {
        -webkit-box-shadow: 0 0 0 15px rgba(254, 57, 5, 0);
        -moz-box-shadow: 0 0 0 15px rgba(254, 57, 5, 0);
        box-shadow: 0 0 0 15px rgba(254, 57, 5, 0);
    }
}

@keyframes ring {
    0% {
        transform: rotate(0deg);
    }
    5% {
        transform: rotate(0deg);
    }
    15% {
        transform: rotate(0deg);
    }
    25% {
        transform: rotate(20deg);
    }
    35% {
        transform: rotate(-15deg);
    }
    45% {
        transform: rotate(10deg);
    }
    55% {
        transform: rotate(-5deg);
    }
    60% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(0deg);
    }
}

.modal {
    background: rgba(0, 0, 0, .3);
}

body.modal-open {
    height: 100vh;
    overflow-y: hidden !important;
}

.today {
    background-color: red;
    color: white
}